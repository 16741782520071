import React from 'react';
import '../styles/Home.css'; // Ensure the path is correct
import { Container, Row, Col, Card } from 'react-bootstrap';

const Home = () => {
  return (
    <div className="home-container">
      <header className="home-header">
        <div className="overlay">
          <h1>Welcome to Rapidoweb</h1>
          <p>Your reliable partner for pay-as-you-go web design services</p>
        </div>
      </header>
      <section className="services-section">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={6} lg={4} className="d-flex">
              <Card className="service-card flex-fill">
                <Card.Body>
                  <Card.Title>Free Consultation</Card.Title>
                  <Card.Text>
                    Get a free consultation to discuss your web design needs and how we can help you achieve your goals.
                  </Card.Text>
                  <div className="price-bubble"><span>0kr</span></div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={4} className="d-flex">
              <Card className="service-card flex-fill">
                <Card.Body>
                  <Card.Title>Pay as You Go Web Design</Card.Title>
                  <Card.Text>
                    Our pay-as-you-go web design service includes:
                    <ul>
                      <li>Free design</li>
                      <li>Hosting</li>
                      <li>Updates</li>
                      <li>Maintenance</li>
                      <li>Social media integrations</li>
                      <li>SEO optimization</li>
                    </ul>
                  </Card.Text>
                  <div className="price-bubble"><span>500kr (exkl. moms)</span></div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={4} className="d-flex">
              <Card className="service-card flex-fill">
                <Card.Body>
                  <Card.Title>Multimedia Services</Card.Title>
                  <Card.Text>
                    Our multimedia services include:
                    <ul>
                      <li>Video session</li>
                      <li>Photo session</li>
                      <li>Social media content creation</li>
                    </ul>
                  </Card.Text>
                  <div className="price-bubble"><span>Coming Soon</span></div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Home;
