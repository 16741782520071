import React from 'react';

const About = () => {
  return (
    <div>
      <h1>About Us</h1>
      <p>We provide top-notch website design services tailored to your needs.</p>
    </div>
  );
}

export default About;
