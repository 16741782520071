import React from 'react';

const Contact = () => {
  return (
    <div>
      <h1>Contact Us</h1>
      <p>Get in touch with us for more information.</p>
    </div>
  );
}

export default Contact;
